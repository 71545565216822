<template>
<div>
  <div class="d-flex justify-content-between align-content-center border-bottom py-2">
    <h3 class="text-primary mb-2">{{$t('payment.addNewCreditCard')}}</h3>
  </div>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(addNewPayment)">
  <b-row class="mt-4 mb-3">
    <b-col md="6">
      <input-form
          class="mb-3"
          :name="$t('payment.clientName')"
          :label="$t('payment.clientName')"
          :placeholder="$t('payment.clientName')"
          :validate="'required'"
          v-model="cardInfo.fullName"
      />
      <input-form
          class="mb-3"
          :validate="'required|numeric'"
          :name="$t('payment.monthEnd')"
          :label="$t('payment.monthEnd')"
          :placeholder="$t('payment.monthEnd')"
          v-model="cardInfo.monthENd"
      />
      <input-form
          class="mb-3"
          :validate="'required|numeric|min:3|max:3'"
          :name="$t('payment.cvv')"
          :label="$t('payment.cvv')"
          :placeholder="$t('payment.cvv')"
          v-model="cardInfo.cvv"
      />
    </b-col>
    <b-col md="6">
      <input-form
          class="mb-3"
          :name="$t('payment.cardNumber')"
          :validate="'required|numeric|digits:16'"
          :label="$t('payment.cardNumber')"
          :placeholder="$t('payment.cardNumber')"
          v-model="cardInfo.cardNumber"
      />
      <input-form
          class="mb-3"
          :name="$t('payment.yearEnd')"
          :validate="'required|numeric'"
          :label="$t('payment.yearEnd')"
          :placeholder="$t('payment.yearEnd')"
          v-model="cardInfo.yearEnd"
      />
    </b-col>
  </b-row>
  <div class="d-flex justify-content-center align-items-center w-35 m-auto mt-3">
    <b-button type="submit" variant="primary" class=" px-2 py-2 mb-2" block><span class="font-size-16">{{ $t('payment.addNewCredit') }}</span></b-button>
  </div>
    </b-form>
  </ValidationObserver>
</div>
</template>

<script>
export default {
  name: 'addNewPaymentModel',
  data () {
    return {
      cardInfo: {
        fullName: '',
        cardNumber: '',
        monthENd: '',
        yearEnd: '',
        cvv: ''
      }
    }
  },
  methods: {
    addNewPayment () {
      this.$bvModal.hide('add-new-card')
    }
  }
}
</script>

<style>
@media (max-width: 575.98px) {
  h3{
    font-size: 18px !important;
  }
  .w-35{
    width: 40% !important;
  }
}
</style>
