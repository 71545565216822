<template>
  <b-container>
    <template v-if="cartItem || cartItem?.cart_products?.length">
      <address-modal
        @addAddress="getAddress"
        :address="editedAddress"
      ></address-modal>

      <b-row class="mt-5 payment-actions-row flex-row-reverse">
        <b-col xl="5" class="mb-4">
          <discount-widget
            :discountValue="cartItem.discount_amount"
            :discountCode="cartItem?.coupon?.code"
          />

          <order-info></order-info>
        </b-col>
        <b-col xl="7" class="payment-info mb-5">
          <div class="d-flex justify-content-between align-content-center">
            <h4 class="text-primary mb-2">{{ $t("payment.myAddress") }}</h4>
            <app-button
              class="w-25"
              @click.native="
                editedAddress = {};
                $bvModal.show('add-address');
              "
            >
              {{ $t("payment.addNewAddress") }}
            </app-button>
          </div>
          <b-card class="my-4 p-0" v-for="locate in locations" :key="locate.id">
            <label
              class="address-card d-flex justify-content-between mb-0"
              :class="{
                'active-address': selectedAddress == locate.id,
              }"
            >
              <b-form-radio
                class="radio-payment-actions d-none"
                v-model="selectedAddress"
                :value="locate.id"
                :validate="'required'"
                name="plain-inline"
                plain
              >
              </b-form-radio>
              <div class="d-flex flex-column">
                <p class="mb-0 font-weight-bold">{{ locate.title }}</p>
                <p class="mb-0">{{ locate.address }}</p>
              </div>
              <button
                class="edit-old-address bg-transparent border-0 text-primary"
                @click="
                  editedAddress = locate;
                  $bvModal.show('add-address');
                "
              >
                {{ $t("payment.edit") }}
              </button>
            </label>
          </b-card>

          <div class="d-flex justify-content-between align-content-center">
            <h4 class="text-primary my-2">
              {{ $t("payment.paymentOptions") }}
            </h4>
          </div>
          <payment-component @pay="addOrder" :four-grid="true" />
        </b-col>
      </b-row>
    </template>
    <empty-cart class="pt-4" v-else />
    <loader />
  </b-container>
</template>

<script>
import { core } from "@/config/pluginInit";
import PaymentCard from "../../components/cart/paymentCard";
import addNewPaymentModel from "@/modules/servicePages/marketplace/components/cart/paymentModels/addNewPaymentModel";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";

import paymentComponent from "@/components/payment";
import cartFunctions from "@/mixins/cartFunctions";
import paymentMixin from "@/mixins/payWithCard";
import AddressModal from "../../../../../components/modal/addressModal.vue";
import OrderInfo from "../../components/checkout/orderInfo.vue";
import Loader from "../../../../../components/core/loader/Loader.vue";
import EmptyCart from "../../components/cart/emptyCart.vue";
import DiscountWidget from "../../components/checkout/discountWidget.vue";
import AppButton from "../../../../../components/buttons/appButton.vue";

export default {
  name: "payment-actions",
  components: {
    PaymentCard,
    addNewPaymentModel,
    paymentComponent,
    AddressModal,
    OrderInfo,
    Loader,
    EmptyCart,
    DiscountWidget,
    AppButton,
  },
  mixins: [cartFunctions, paymentMixin],
  data() {
    return {
      locations: [],
      allItems: {},
      selectedAddress: null,
      editedAddress: {},
      showPaymentDiv: false,
    };
  },
  computed: {
    cartItem() {
      return this.$store.getters["cart/getStoreDetailsAndProducts"](
        this.$route.params.id
      );
    },
  },

  methods: {
    getAddress() {
      marketPlace.getAddress().then((res) => {
        this.locations = res.data.data;
        if (!this.selectedAddress) {
          this.selectedAddress = this.locations[0].id;
        }
      });
    },

    async addOrder(type) {
      const addressErrorMessage =
        this.$i18n.locale === "ar"
          ? "يجب أختيار العنوان"
          : "You must select the address";
      if (!this.selectedAddress)
        return core.showSnackbar("error", addressErrorMessage);
      this.$store.commit("Setting/setLoading", true);
      try {
        type == "wallet"
          ? await this.payWithWallet()
          : await this.payWithCard();
      } finally {
        this.$store.commit("Setting/setLoading", false);
      }
    },
    async payWithCard() {
      const cartId = this.$route.params.id;
      const addressId = +this.selectedAddress;
      const { data } = await marketPlace.payOrder({
        cartId,
        customer_addres_id: addressId,
        type: "card",
      });
      window.open(data.checkout_url, "_self");
    },
    async payWithWallet() {
      const cartId = this.$route.params.id;
      const addressId = +this.selectedAddress;
      try {
        const { data } = await marketPlace.payOrder({
          cartId,
          customer_addres_id: addressId,
          type: "wallet",
        });
        core.showSnackbar("success", data.message);
        this.$store.commit("cart/DELETE_STORE", cartId);
        this.$router.push({ name: "user-marketplace" });
      } catch (err) {
        core.showSnackbar("error", err.response.data.message);
      }
    },
  },

  created() {
    this.getAddress();
  },

  beforeRouteEnter(to, from, next) {
    if (!localStorage.getItem("userToken")) {
      next({ name: "auth" });
    } else next();
  },
};
</script>

<style lang="scss">
.input-payment {
  background-color: #f4f4f4 !important;
  border-radius: 0 !important;
  border: 0 !important;
}

.address-card {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &.active-address {
    border: 1px solid var(--iq-primary) !important;
  }
}

.payment-store-img {
  width: 62px !important;
  height: 62px !important;
  border-radius: 10px;
  overflow: hidden;
}

.payment-store-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.payment-mada-img {
  height: 35px;
  width: 55px;
}

.payment-mada-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}

input[name*="plain-inline"] {
  accent-color: green;
}

.w-35 {
  width: 35% !important;
}

@media (min-width: 768px) {
  .payment-info {
    position: sticky !important;
    top: 10px;
    height: fit-content !important;
  }
}

.order-select {
  background: #f2f2f2;
}

.edit-btn {
  border-radius: 4px !important;
}

.store-detail-btn {
  height: 45px;
  border: none !important;
  background: #1f453e !important;
}
</style>

