<template>
  <div>
    <DepositModal />

    <b-row class="mb-5">
      <b-col sm="6" :md="fourGrid ? 6 : 4" v-if="ifLogin">
        <div class="fees-card">
          <b-button
            v-b-toggle.collapse-wallet
            class="fees-card-btn position-relative w-100 bg-transparent border-0"
          >
            <div class="fees-card-btn-inner">
              <img
                src="@/assets/images/ibbil/transportation/wallet.svg"
                alt=""
                class="img-fluid"
              />
              <span>{{ $t("payment.e_wallet") }}</span>
            </div>
          </b-button>
          <b-collapse id="collapse-wallet" class="fees-card-body">
            <form class="fees-card-form">
              <b-row>
                <b-col cols="12">
                  <label
                    for="wallet-panels"
                    class="input-with-icon-label mb-2"
                    >{{ $t("payment.balance") }}</label
                  >
                </b-col>
                <b-col cols="12">
                  <div
                    class="d-flex justify-content-between align-items-center input-with-icon-container overflow-hidden"
                  >
                    <span>
                      {{ balance }}
                    </span>

                    {{ $t("main.sar") }}
                  </div>
                  <b-button class="add-payment" v-b-modal.add-payment>
                    + {{ $t("payment.add_balance") }}
                  </b-button>
                </b-col>

                <b-col cols="12">
                  <div class="mt-3">
                    <b-button
                      @click="$emit('pay', 'wallet')"
                      :disabled="!ableToPay"
                      variant="primary"
                      class="m-auto pay-btn d-flex align-items-center justify-content-center gap_1"
                    >
                      <span>{{ $t("main.pay") }}</span>
                      <span
                        ><i
                          class="las icon"
                          :class="
                            $i18n.locale === 'ar'
                              ? 'la-angle-left'
                              : ' la-angle-right'
                          "
                        ></i
                      ></span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </form>
          </b-collapse>
        </div>
      </b-col>
      <b-col sm="6" :md="fourGrid ? 6 : 4">
        <div class="fees-card">
          <b-button
            v-b-toggle.collapse-master
            class="fees-card-btn position-relative w-100 bg-transparent border-0"
          >
            <div class="fees-card-btn-inner">
              <span>{{ $t("payment.creditCard") }}</span>
            </div>
          </b-button>
          <b-collapse id="collapse-master" class="fees-card-body">
            <form class="fees-card-form pr-4">
              <b-row>
                <div class="payment-card-wrapper w-100 px-5">
                  <!--              <img src="@/assets/images/ibbil/payments/apple_pay.svg" alt="" class="img-h " />-->
                  <img
                    src="@/assets/images/ibbil/transportation/master-card.svg"
                    alt=""
                    class="w-50"
                  />
                  <img
                    src="@/assets/images/ibbil/payments/visa.svg"
                    alt=""
                    class="w-50"
                  />
                  <img
                    src="@/assets/images/ibbil/payments/amex.svg"
                    alt=""
                    class="w-100"
                  />
                </div>
                <b-col cols="12">
                  <div class="mt-4">
                    <b-button
                      @click="$emit('pay')"
                      variant="primary"
                      class="m-auto pay-btn d-flex align-items-center justify-content-center gap_1"
                    >
                      <span>{{ $t("main.pay") }}</span>
                      <span
                        ><i
                          class="las icon"
                          :class="
                            $i18n.locale === 'ar'
                              ? 'la-angle-left'
                              : ' la-angle-right'
                          "
                        ></i
                      ></span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </form>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import websiteServices from "@/modules/userProfile/services/userProfile";
import Payment from "@/Utils/payment";
import DepositModal from "./modal/depositModal.vue";

export default {
  data() {
    return {
      // hyperpayForm: false,
      ifLogin: localStorage.getItem("userToken"),
      balance: "",
      wallet: {
        panels: "",
        PIN_code: "",
      },
      deposit: {
        amount: "",
      },
    };
  },
  watch: {},
  components: { DepositModal },
  props: {
    fourGrid: {
      default: false,
    },
  },
  computed: {
    cartTotalAmount() {
      return +this.$store.getters["cart/getStoreDetailsAndProducts"](
        this.$route.params.id
      )?.total_amount;
    },
    ableToPay() {
      return +this.balance >= this.cartTotalAmount;
    },
  },
  methods: {
    payWithWallet() {
      this.$emit("payWithWallet", { id: 2, PIN_code: this.wallet.password });
      this.ableToPay = false;
      this.wallet.password = "";
    },

    getWallaetDetails() {
      websiteServices.getWallaetDetails().then((res) => {
        this.balance = res.data.data.balance;
      });
    },
  },
  created() {
    this.getWallaetDetails();
  },
};
</script>
<style lang="scss">
.img-h {
  height: 25px;
}

.fees-card {
  background-color: #fff;
  box-shadow: 0 3px 20px #0000000d;
  border-radius: 4px;

  .fees-card-btn {
    padding: 16px 20px !important;
    border-radius: inherit;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #494845 !important;

    .fees-card-btn-inner {
      width: 100%;
      white-space: nowrap;
      transition: 0.5s;

      img {
        min-width: 35px;
        margin-inline-end: 12px;
      }
    }

    &.not-collapsed {
      .fees-card-btn-inner {
        width: 0;
      }

      &::after {
        display: block;
      }
    }

    &::after {
      content: "";
      display: block;
      margin-top: 16px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      display: none;
    }
  }

  .fees-card-form {
    padding: 16px 20px;
  }

  .input-with-icon-label {
    font-size: 16px !important;
    color: #494845 !important;
    font-weight: 600 !important;
    margin-bottom: 8px !important;
  }

  .input-with-icon-container {
    background-color: #f7f7f7;
    height: 50px;
    border-radius: 4px !important;
    padding-inline: 20px;
    color: #494845;

    .input-with-icon {
      font-size: 16px;

      &.panels {
        height: 100%;
        background: transparent !important;
        flex: 1;
        padding-inline-start: 20px !important;
        color: #6ebf97;
        font-size: 25px;
        font-weight: 600;

        &:focus {
          color: #6ebf97;
        }

        &.border-0 {
          border: none !important;
        }
      }
    }

    .icon {
      color: #494845;
      font-size: 20px;
      min-width: auto;
    }
  }

  .wallet-add-btn {
    background-color: rgba(0, 68, 61, 0.27) !important;
    color: #00443d !important;
    font-size: 16px !important;
    padding: 16px 11px !important;
    border-radius: 4px !important;
  }

  .pay-btn {
    min-width: 100%;
    height: 50px;
    background-color: #3f6854 !important;
    border-radius: 4px !important;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }
}
.payment-card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
}
.form-control {
  background: #f7f7f7 !important;
}
.back {
  color: #1f453e !important;
}
.add-payment {
  background: none !important;
  text-decoration: underline !important;
  color: #000 !important;
  font-size: 1rem !important;
  border: none !important;
  text-transform: capitalize !important;
  text-underline-offset: 2px !important;
}
</style>
