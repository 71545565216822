import Payment from '@/Utils/payment'

export default {
  methods: {
    async payWithCard (config, pathUrl) {
      const gateway = 'Noon'
      const Pay = new Payment(gateway, { ...config, return_url: pathUrl })
      const { url } = await Pay.paymentConfig()
      console.log(url)
      if (gateway.toLowerCase() === 'hyperpay') {
        this.hyperpayForm = true
        const script = document.createElement('script')
        script.src = url
        document.body.appendChild(script)
        const form = document.createElement('form')
        form.classList.add('paymentWidgets')
        form.setAttribute('data-brands', 'VISA MASTER AMEX')
        form.action = pathUrl
        document.querySelector('.hyperpay-form').appendChild(form)
      }
    }
  }
}
