import { core } from '@/config/pluginInit';

export default {
  data () {
    return { loading: false };
  },
  methods: {
    async showLoading (cb, payload) {
      this.loading = true;
      await cb(payload);
      this.loading = false;
    },
    async increaseQuantity (payload) {
      let { quantity, ...rest } = payload;
      this.$store.commit('Setting/setLoading', true);
      try {
        await this.$store.dispatch('cart/updateCart', { ...rest, quantity: quantity + 1 });
      }
      catch (err) {
        console.log(err);
      }
      finally {
        this.$store.commit('Setting/setLoading', false);
      }
    },
    async decreaseQuantity (payload) {
      if (payload.quantity !== 1) {
        let { quantity, ...rest } = payload;
        this.$store.commit('Setting/setLoading', true);
        try {
          await this.$store.dispatch('cart/updateCart', { ...rest, quantity: quantity - 1 });
        }
        catch (err) {
          console.log(err);
        }
        finally {
          this.$store.commit('Setting/setLoading', false);
        }
      }
      else {
        core.showSnackbar('error', 'لا يمكن تقليل الكمية');
      }
    },
    async deleteProductInCart (payload) {
      this.$store.commit('Setting/setLoading', true);
      await this.$store.dispatch('cart/deleteProductInCart', payload);
      this.$store.commit('Setting/setLoading', false);
    },
    async deleteStoreFromCart (id) {
      await this.$store.dispatch('cart/deleteStoreFromCart', id);
    }

  },
  created () {
  }
};
