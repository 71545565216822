<template>
  <div class="order-info__product">
    <div class="d-flex align-items-center gap_1">
      <img
        :src="product.featured_image"
        :alt="product.name"
        width="50"
        height="50"
        class="rounded-sm"
      />
      <h6 class="font-weight-bold text-muted">{{ product.title }}</h6>
    </div>
    <div class="d-flex align-items-center">
      <span class="font-weight-bold text-muted">
        {{ product.price }}
      </span>
      <i class="las la-times"></i>
      <span class="font-weight-bold text-muted">
        {{ product.quantity }}
      </span>
    </div>
    <span class="font-weight-bold text-muted">
      {{ calcProductPrice(product.price, product.quantity) }}
      {{ $t("main.sar") }}
    </span>
    <button class="order-info__delete-button" @click="$emit('deleteProduct')">
      <i class="las la-trash"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "order-product-card",
  props: ["product"],
  methods: {
    calcProductPrice(price, quantity) {
      return (price * quantity).toFixed(2);
    },
  },
};
</script>

<style lang="scss">
.order-info {
  &__product {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr auto;
    align-items: center;
    margin-bottom: 1rem;
    @media (max-width: 991px) {
      grid-template-columns: 3fr;
    }
  }

  &__delete-button {
    border: 1px solid #a3a3a3;
    background: transparent;
    border-radius: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    i {
      color: #a3a3a3;
      font-size: 25px;
    }
  }
}
</style>