<template>
  <!-- loader Start -->
  <div id="loading" v-if="loading">
    <div id="loading-center"></div>
  </div>
  <!-- loader END -->
</template>
<script>
export default {
  name: "Loader",
  computed: {
    loading() {
      return this.$store.getters["Setting/loading"];
    },
  },

  watch: {
    // loading: {
    //   handler: function (val) {
    //     if (val) {
    //       document.body.style.overflow = "hidden";
    //     } else {
    //       document.body.style.overflow = "auto";
    //     }
    //   },
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss" scoped>
#loading {
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  #loading-center {
    width: 2rem;
    height: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid var(--iq-primary);
    border-top-color: transparent;
    animation: loader 0.8s infinite linear;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>